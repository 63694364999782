import React, { useEffect } from 'react'

const GoogleAuth = () => {
  useEffect(() => {
    // get the URL parameters which will include the auth token
    const params = window.location.search
    if (window.opener) {
      // send them to the opening window
      window.opener.postMessage({
        appId: 'qa-oauth-app',
        code: params.split('&')[0].split('=')[1],
      })
      // close the popup
      window.close()
    }
  })
  // some text to show the user
  return <p>Please wait...</p>
}

export default GoogleAuth
